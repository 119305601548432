/* .main-content.guest {
	background-image: url('../resources/images/admatic-bg.jpg');
	min-height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
} */

a {
	color: #1a73e8;
}

body, .makeStyles-bgDefault-1, .makeStyles-bgDefault-216, .makeStyles-bgDefault-219 {
	background-color: #fff !important;
}

.react-bootstrap-table td{
    white-space: normal !important;
}

.login-logo {
	width:  200px;
}

.makeStyles-logoClasses-16, .jss16 {
	max-height: 6rem !important;
}

.full-page-loader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.large-icon {
  	font-size: '50px' !important;
}

.add-logo-dropzone {
	width: 250px;
	margin:  0 auto;
}

.category-card {
	height: 250px;
	cursor:  pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.category-card:hover {
	background-color: rgba(0, 0, 0, 0.04);
}

.category-card p {
	margin: 0;
	font-size: 1.5rem;
}

.MuiPickersCalendarHeader-transitionContainer {
	overflow: unset !important;
}

.bold-text {
	font-weight: 700;
}

.hover-text {
	cursor:  pointer;
}

.hover-text:hover {
	text-decoration: underline;
}

.MuiOutlinedInput-input.Mui-disabled {
    background-color: #e9ecef;
}

.dropzone {
	text-align: center;
	cursor:  pointer;
}

.category-icon {
	font-size: 2.5rem !important;
	width:  2.5rem !important;
	height:  2.5rem !important;
}

.register-icon {
	font-size: 3rem !important;
	width:  3rem !important;
	height:  3rem !important;
	color:  #2dce89;
}

.image-modal-img {
	max-width: 100%;
}


/* home */

.home-check-icon {
	font-size: 2rem !important;
	width:  2rem !important;
	height:  2rem !important;
	color:  #fff;
	margin-right: 0.5rem;
}

.home-logo {
	width:  300px;
}

/*.MuiBox-root-107 {
	position: initial !important;
}*/

.hover-text {
	cursor: pointer;
}

.red-text {
	color: #f5365c;
}

.primary-text {
	color: #1a73e8;
}

.profile-accordion {
	padding-top: 2em !important;
}

.MuiAccordionSummary-content {
	justify-content: space-between !important;
	align-items: center !important;
}

.dataTables_filter {
	padding-left: 0.5rem !important;
}

.fc-event.completed {
	color: #eee;
}

.account-type-badge {
	color: #5fb2ee;
	border: 1px solid #5fb2ee;
	border-radius: 5px;
	padding: 0.5rem;
	opacity: 0.7;
}

.online-discount {
	color: #5fb2ee;
	border: 1px solid #5fb2ee;
	border-radius: 5px;
	padding: 0.5rem;
}

.stage-color {
	width: 10px;
	height: 10px;
	margin-right: 0.5rem;
	border-radius: 50%;
}

.pipeline-btn-group {
	display: flex !important;
}

.pipeline-btn {
	flex: 1;
}

.react-bootstrap-table .filter-label {
	width: 100%;
}

.fixed-height-modal .MuiDialog-paperScrollPaper {
	height: calc(100% - 64px);
}

.quote-details-card {
	position: sticky !important;
	top: 1rem !important;
}

.lead-modal .MuiDialog-paperScrollPaper {
	height: calc(100% - 64px) !important;
}

.stage-select .MuiSelect-select {
	display: flex !important;
	flex-direction: row;
	align-items: center;
}

.MuiMenuItem-root {
	display: flex !important;
	flex-direction: row;
	align-items: center;
}

.react-bootstrap-table th[data-row-selection] {
	width: 50px;
}

.activity-container p {
	color: #525f7f;
}

.task-container.past p {
	opacity: 0.3;
}

.checkbox-menu-item .MuiListItemText-primary{
	font-size: 1rem !important;
}

.normal-text {
	color: #525f7f;
	text-align: left;
	margin-bottom: 0.2rem;
}

.warning-text {
	color: #fb6340;
}

.inactive-delivery {
	opacity: 0.3;
}

.invisible-text {
	opacity: 0;
}

.lead-tooltip p {
	margin-bottom: 0;
}

.table-container {
	overflow: scroll;
}

.react-bootstrap-table table {
	table-layout: auto;
}